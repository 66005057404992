<template>
    <div>
        <common-header>保險</common-header>
        <iframe ref="myIframe" :src="srcObj[id]" width="100%" height="800" frameborder="0" scrolling="auto" id="iframename" name="iframename"></iframe>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
export default {
    data() {
        return {
            id: '',
            srcObj: {
                1: 'https://etong.epicc.com.cn:8872/proshare/#/picker?id=6e9fb55ddb8c4fedafbe59289932d66c&prpdusertype=1&groupid=&plancode=WBK00000ag&flag=1',
                2: 'https://etong.epicc.com.cn:8872/proshare/#/picker?id=5eccbddb625c4f5c86e050eab421290b&prpdusertype=1&groupid=&plancode=EAA350000i&flag=1',
                3: 'https://etong.epicc.com.cn:8872/proshare/#/picker?id=597b9b4976ed44ad967cb6f8b0541066&prpdusertype=1&groupid=&plancode=EBJ0000003&flag=1'
            }
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.id = this.$route.query['id']
    }
}
</script>
<style lang="scss" scoped>

</style>